<template>
    <div class="body-container">
        <MainBannerSection />

        <div class="shop-our-categories-container">
            <div class="shop-categories-heading" @click="showToast('success')" >SHOP OUR TOP CATEGORIES</div>
        </div>
        <!-- {{ topCategories }} -->
        <TopParentCategoriesSection :topCategories="topCategories" @goToShopPage="goToShopPage" />

        <div class="furniture-type-parent-container">
            <div class="bed-furniture-bg-section">
                <div class="bg-text">
                    HOME <span class="furniture-text-section">FURNITURE</span>
                </div>
            </div>
            <div class="furniture-type-category-container">
                <div class="furniture-type-category-headline">
                    LIVING ROOM FURNITURE |
                    <span class="span-text">Category</span>
                </div>

                <ChildrenCategoriesSection :categories="livingRoomCategories" />

                <div class="furniture-type-top-container">
                    <div class="furniture-type-top-headline">
                        LIVING ROOM TOP PICKS
                    </div>
                    <TopFurnituresSection :items="topLivingRoomItems" />
                </div>
            </div>
        </div>

        <div class="ar-introduction-container"></div>

        <div class="furniture-type-parent-container">
            <div class="bed-furniture-office-bg-section">
                <div class="bg-text">
                    OFFICE <span class="furniture-text-section">FURNITURE</span>
                </div>
            </div>

            <div class="furniture-type-category-container">
                <div class="furniture-type-category-headline">
                    OFFICE FURNITURE | <span class="span-text">Category</span>
                </div>

                <ChildrenCategoriesSection :categories="officeRoomCategories" />
                <div class="furniture-type-top-container">
                    <div class="furniture-type-top-headline">
                        OFFICE TOP PICKS |
                        <span class="span-text">Our seasonal hits and everyday favorites—find
                            yours!</span>
                    </div>
                    <TopFurnituresSection :items="topOfficeRoomItems" />
                </div>
            </div>
        </div>
    </div>
    <!-- <Toaster /> -->
    <!-- <Toaster ref="toast"/> -->
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import MainBannerSection from "@/views/index-view/MainBannerSection.vue";
import TopParentCategoriesSection from "@/views/index-view/TopParentCategoriesSection.vue";
import ChildrenCategoriesSection from "@/views/index-view/ChildrenCategoriesSection.vue";
import TopFurnituresSection from "@/views/index-view/TopFurnituresSection.vue";

import { productsClient, itemIndicesClient, categoriesClient } from "@/assets/apis/config";

import { useCategoryStore } from "@/stores/category-store";
// import Toaster from "~/components/commons/Toaster.vue";

const page = ref(1);
const size = ref(20);
const categoryStore = useCategoryStore();
const topCategories = ref([]);
const livingRoomCategories = ref([]);
const officeRoomCategories = ref([]);
const route = useRoute();
const router = useRouter();
const topLivingRoomItems = ref([]);
const topOfficeRoomItems = ref([]);
const toast = ref(null);

// const goToShopPage = (item) => {
//     let obj = {
//         categoryId: item?.id?.length ? item.id : "",
//         categoryName: item?.name?.length ? item.name : "",
//         categoryBanner: item?.extras?.banner_image1_url?.length ? item.extras.banner_image1_url : "",
//     };
//     router.push({
//         name: "shop",
//         query: obj,
//     });
// };

const parentId = computed(() => {
    let livingRoom = "";
    let officeRoom = "";
    if (categoryStore.categories && categoryStore.categories.length) {
        let living = categoryStore.categories.filter(
            (i) => !i.parentId && i.name == "Living Room"
        );
        livingRoom = living.length ? living[0].id : "";
        let office = categoryStore.categories.filter(
            (i) => !i.parentId && i.name == "Office"
        );
        officeRoom = office.length ? office[0].id : "";
    }

    return {
        living: livingRoom,
        office: officeRoom,
    };
});
const setLoading = async () => {
    let shimmers = [];

    for (let i = 0; i < 10; i++) {
        shimmers.push({ isLoading: true });
    }

    return shimmers;
};

const fetchTopLivingRoomCategories = async (id) => {
    let params = {
        textParams: [],
        sortParams: [{ field: "name", type: "ASCENDING" }],
        page: page.value,
        size: size.value,
    };

    if (id.length) {
        params.textParams.push({
            field: "parentId",
            value: id,
            matchType: "EXACT",
        });
    }
    let res = await categoriesClient.search(params);

    if (!res.networkError) {
        if (res.status == 200 && res?.body?.list?.length) {
            topCategories.value = res.body.list.sort(
                (a, b) => a.sortOrder - b.sortOrder
            );
            livingRoomCategories.value = topCategories.value;
        }
    }
};

const fetchTopOfficeCategories = async (id) => {
    let params = {
        textParams: [],
        sortParams: [{ field: "name", type: "ASCENDING" }],
        page: page.value,
        size: size.value,
    };

    if (id.length) {
        params.textParams.push({
            field: "parentId",
            value: id,
            matchType: "EXACT",
        });
    }

    let res = await categoriesClient.search(params);

    if (!res.networkError) {
        if (res.status == 200 && res?.body?.list?.length) {
            topCategories.value = res.body.list.sort(
                (a, b) => a.sortOrder - b.sortOrder
            );
            officeRoomCategories.value = topCategories.value;
        }
    }
};

const fetchTopLivingRoomProduct = async (id) => {
    if (id?.length) {
        let params = {
            query: "",
            page: page.value,
            size: size.value,
            filters: [
                { field: "categoryIds", values: [id] }
            ],
            nonZeroStockOnly: false,
        };

        let res = await itemIndicesClient.search(params)
        if (!res.networkError && res.status == 200 && res?.body?.list?.length) {
            topLivingRoomItems.value = res.body.list.splice(0, 12);
        }
    }
};

const fetchTopOfficeRoomProduct = async (id) => {
    if (id?.length) {
        let params = {
            query: "",
            page: page.value,
            size: size.value,
            filters: [
                { field: "categoryIds", values: [id] }
            ],
            nonZeroStockOnly: false,
        };
    
        let res = await itemIndicesClient.search(params);
        if (!res.networkError && res.status == 200 && res?.body?.list?.length) {
            topOfficeRoomItems.value = res.body.list.splice(0, 12);
        }
    }
};

const showToast = (val) => {
    if (toast.value != null && val == 'success') {
        toast.value.success("This is Success Toast" );
    } else if (val == 'error') {
        toast.value.error("This is Error Toast", 1500 );
    } else if (val == 'info') {
        toast.value.info("This is Info Toast", 1500 );
    } else if (val == 'warning') {
        toast.value.warning("This is Warning Toast", 1500 );
    }
}

watch(parentId, async () => {
    if (parentId.value) {
        await fetchTopLivingRoomCategories(parentId.value.living);
        await fetchTopOfficeCategories(parentId.value.office);
        await fetchTopLivingRoomProduct(parentId.value.living);
        await fetchTopOfficeRoomProduct(parentId.value.office);
    }
}, {immediate: true});

onMounted(async () => {
    // topLivingRoomItems.value = await setLoading();
    // topOfficeRoomItems.value = await setLoading();
});
</script>
