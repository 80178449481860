<template>
    <div >
        <div v-if="isLoading || !bannerData.length">
            <Skeletor
                class="label" style="margin-bottom: 0.5rem; width: 100%; height: 70vh; border-radius: 0;"
            />
        </div>
        <carousel
            style="width: 100%;"
            ref="myCarousel"
            :breakpoints="breakpoints"
            :settings="settings"
            :wrap-around="true"
            v-else
		>

			<template #slides>
				<slide 
                    v-for="(banner, j) in bannerData" 
                    :key="j" 
                    class="background-image-section"
                    :style="{'background-image': `url(${banner.url})`}"
                >
					
				</slide>
			</template>

			<template #addons>
				<!-- <Navigation /> -->
                <Pagination />
			</template>

        </carousel>
    </div>
</template>

<script setup>
import { ref, toRef, computed, onMounted } from "vue";

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { cmsClient } from "@/assets/apis/config";
import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";

const bannerData = ref([]);
const isLoading = ref([]);

const settings = ref({
	itemsToShow: 1,
	snapAlign: "center",
    autoplay: 3500,
    loop: true
});

const breakpoints = ref(
//   {
//     0: {
//       itemsToShow: 1,
//       snapAlign: "center",
//     },
//     300: {
//       itemsToShow: 2,
//       snapAlign: "center",
//     },
//     700: {
//       itemsToShow: 3,
//       snapAlign: "center",
//     },
//     991: {
//       itemsToShow: 4,
//       snapAlign: "center",
//     },
//     1275: {
//       itemsToShow: 5,
//       snapAlign: "center",
//     },
//   }
);

const myCarousel = ref(null);

const fetchSliderImages = async () => {
    isLoading.value = true;
    cmsClient.fetchSliderImages({size: 10}).then((res)=> {
        if (res.status == 200 && !res.networkError) {
            if (res.body && res.body.list && res.body.list.length > 0) {
                let list = res.body.list;
                bannerData.value = list.sort((x,y)=> x.sortOrder - y.sortOrder);
                isLoading.value = false;
            }
        }
    })
}

onMounted(async () => {
  await fetchSliderImages();
});
</script>

<style lang="scss" scoped>
.carousel__pagination {
    width: 100%;
    margin: 0 !important;
    position: absolute !important;
    bottom: 3% !important;
}
@media screen and (max-width: 700px){
    .carousel__pagination {
        left: -6% !important;
    }
}
.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: red;
  color: red;
}
</style>