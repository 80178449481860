<template>
    <div class="furniture-type-category" v-if="childCategories.length > 0">
        <carousel
            style="width: 100%"
            ref="myCarousel"
            :breakpoints="breakpoints"
            :settings="settings"
            class="product_slider"
            :wrap-around="true"
        >
            <template #slides>
                <slide v-for="(category, j) in childCategories" :key="j">
                    <ChildrenCategoryItem
                        class="carousel__item"
                        :category="category"
                    />
                </slide>
            </template>

            <template #addons>
                <Navigation />
            </template>
        </carousel>
    </div>
</template>

<script setup>
import { ref, toRef, computed } from "vue";
import ChildrenCategoryItem from "./items/ChildrenCategoryItem.vue";

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const props = defineProps({
    categories: {
        type: Array,
        default: () => [],
    },
});

const settings = ref({
    itemsToShow: 5,
    snapAlign: "start",
});

const breakpoints = ref({
    0: {
        itemsToShow: 1,
        snapAlign: "start",
    },
    500: {
        itemsToShow: 2,
        snapAlign: "center",
    },
    1024: {
        itemsToShow: 3,
        snapAlign: "start",
    },
    1600: {
        itemsToShow: 4,
        snapAlign: "start",
    },
});

const myCarousel = ref(null);

const categoriesRef = toRef(props, "categories");

const childCategories = computed(() => {
    let children = [];

    if (categoriesRef.value && categoriesRef.value.length > 0) {
        if (
            categoriesRef.value[0].children &&
            categoriesRef.value[0].children.length > 0
        ) {
            return categoriesRef.value[0].children;
        } else {
            return categoriesRef.value;
        }
    }

    return children;
});
</script>

<style lang="scss" scoped></style>
