<template>
    <div class="categories-parent-container">
        <div class="categories-left-section">
            <div class="left-upper-section left-upper-section--animation-from-right cursor-pointer" @click="goToShopPage(firstSlot.item)">
                <div class="upper-section-image" v-if="firstSlot.bannerImages.length > 0">
                    <div class="l-image" :style="{backgroundImage: firstSlot.image}"></div>
                </div>

                <div v-else class="upper-section-image">
                    <div class="l-image" :style="{backgroundImage: firstSlot.image}">
                    </div> 
                </div>

                <div class="upper-section-box">
                    <div class="upper-section-box-inner">
                        <div>{{ firstSlot.title }}</div>
                        <img class="arrow-button" @click="goToShopPage(firstSlot.item)" height="50" style="cursor: pointer;" src="@/assets/images/icons/arrow-left.png" />
                    </div>
                </div>
            </div>

            <div  v-if="secondSlot.bannerImages.length > 0" class="left-bottom-section cursor-pointer"  @click="goToShopPage(secondSlot.item)">
                <div class="bottom-section-box">
                    <div>{{ secondSlot.title }}</div>
                    <img class="arrow-button" @click="goToShopPage(secondSlot.item)" height="50" style="cursor: pointer;" src="@/assets/images/icons/arrow-right.png" />
                </div>

                <div class="bottom-section-image" >
                    
                    <div class="l2-image" :style="{backgroundImage: secondSlot.image}">
                    </div>
                </div>
            </div>

            <div v-else class="left-bottom-section" >
                <div class="l2-image" :style="{backgroundImage: secondSlot.image}">
                </div>
            </div>
        </div>

        <div class="categories-right-section cursor-pointer" @click="goToShopPage(thirdSlot.item)">
            <div class="right-bottom-section-image">
                <div class="r-image" :style="{backgroundImage: thirdSlot.image}">
                </div>
            </div>

            <div class="right-uppper-section-box" >
                <div>{{ thirdSlot.title }}</div> 
                <img @click="goToShopPage(thirdSlot.item)" height="50" style="cursor: pointer;"  src="@/assets/images/icons/arrow-up.png" />
            </div>
        </div>
        
        <div class="categories-right-mobile-section">
            <div class="left-upper-section left-upper-section--animation-from-right" >
                <div class="upper-section-image" >
                    <div class="l-image" :style="{backgroundImage: thirdSlot.image}"></div>
                </div>

                <div class="upper-section-box">
                    <div class="upper-section-box-inner">
                        <div>{{ thirdSlot.title }}</div>
                        <img class="arrow-button" @click="goToShopPage(thirdSlot.item)" height="50" style="cursor: pointer;" src="@/assets/images/icons/arrow-left.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { useCategoryStore } from "@/stores/category-store";
const categoryStore = useCategoryStore();

const props = defineProps({
    topCategories: {
        type: Array,
        default: () => []
    }
});
const route = useRoute();
const router = useRouter();

const settings = ref({
	itemsToShow: 1,
	snapAlign: "center",
});

const breakpoints = ref(
  {
    309: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    1920: {
      itemsToShow: 1,
      snapAlign: "center",
    },
  },
);

const myCarousel = ref(null);


const firstSlot = computed(() => {
    
    let data = {
        id: "",
        title: "Living Room",
        item: {},
        image:'url(/images/index/living-room.jpg)',
        bannerImages: ['/images/index/living-room.jpg'],
    };
    let hasRoom = categoryStore.categories.filter(i => i.name && (i.name == "Living Room" || i.name.includes("Living")) && !i.parentId.length );
    if (hasRoom.length > 0 && !hasRoom[0].isLoading) {

        data = {
            id: hasRoom[0].id,
            title: hasRoom[0].name,
            item: hasRoom[0],
            image: '',
            bannerImages: [],
        }

        if (hasRoom[0].extras) {
            Object.keys(hasRoom[0].extras).forEach((key) => {
                if(key.startsWith('shop_banner_image')) {
                    data.image = `url(${hasRoom[0].extras[key]})`
                    data.bannerImages.push(hasRoom[0].extras[key]);
                }
            });
        }
    }

    return data;
});

const secondSlot = computed(() => {
    let data = {
        id: "",
        title: "Bed Room",
        item: {},
        image:'url(/images/index/bed-room.jpg)',
        bannerImages: ['/images/index/bed-room.jpg'],
    };

    let hasRoom = categoryStore.categories.filter(i => i.name && (i.name == "Bed Room" || i.name.includes("Bed")) && !i.parentId.length );
    if (hasRoom.length > 0 && !hasRoom[0].isLoading) {

        data = {
            id: hasRoom[0].id,
            title: hasRoom[0].name,
            item: hasRoom[0],
            image: '',
            bannerImages: [],
        }

        if (hasRoom[0].extras) {
            // data.image = 'url(' + hasRoom[0].extras.shop_banner_image1_url + ')'
            
            Object.keys(hasRoom[0].extras).forEach((key) => {
                if(key.startsWith('shop_banner_image')) {
                    data.image = `url(${hasRoom[0].extras[key]})`
                    data.bannerImages.push(hasRoom[0].extras[key]);
                }
            });
        }
    }

    return data;
});

const thirdSlot = computed(() => {
    let data = {
        id: "",
        title: "Dining Room",
        item: {},
        image:'url(/images/index/dining-room.jpg)',
        bannerImages: ['/images/index/dining-room.jpg'],
    };

    let hasRoom = categoryStore.categories.filter(i => i.name && (i.name == "Dining Room" || i.name.includes("Dining")) && !i.parentId.length );
    if (hasRoom.length > 0 && !hasRoom[0].isLoading) {

        data = {
            id: hasRoom[0].id,
            title: hasRoom[0].name,
            item: hasRoom[0],
            image: '',
            bannerImages: [],
        }

        if (hasRoom[0].extras) {
            // data.image = 'url(' + hasRoom[0].extras.banner_image1_url + ')'
            
            Object.keys(hasRoom[0].extras).forEach((key) => {
                if(key.startsWith('shop_banner_image')) {
                    data.image = `url(${hasRoom[0].extras[key]})`
                    data.bannerImages.push(hasRoom[0].extras[key]);
                }
            });
        }
    }

    return data;
});

const fourthSlot = computed(() => {
    let data = {};
    
    if (props.topCategories && props.topCategories.length > 1) {

        data = {
            id: props.topCategories[3].id,
            title: props.topCategories[3].name,
            item: props.topCategories[3],
            image: '',
            bannerImages: [],
        }

        if (props.topCategories[1].extras) {
            data.image = 'url(' + props.topCategories[3].extras.banner_image1_url + ')'
            
            Object.keys(props.topCategories[3].extras).forEach((key) => {
               
                if(key.startsWith('banner_image')) {
                    data.bannerImages.push(props.topCategories[3].extras[key]);
                }
            });
        }
        
        return  data;
    }

    return data ={
        title: "",
        image:'url(/images/index/bed-room.jpg)',
        bannerImages: ['/images/index/bed-room.jpg'],
    }
});

const goToShopPage = (item) => {
    let obj = {
        categoryId: item?.id?.length ? item.id : "",
        categoryName: item?.name?.length ? item.name : "",
        categoryBanner: item?.extras?.banner_image1_url?.length ? item.extras.banner_image1_url : "",
    };

    router.push({
        name: "shop",
        query: obj,
    });
};

</script>

<style lang="scss" scoped>

.l-image{
    width: 100% !important;
    background-size: cover;
    background-repeat: no-repeat;
}
.l2-image {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.r-image {
    height: 100%;
}
.carousel__pagination{
    display: flex;
    justify-content: center;
    list-style: none;
    line-height: 0;
    margin: 0px 0 0;
    position: absolute;
    bottom: 10px;
    width: 100%;

}
.carousel__pagination-button ::after{
    height: .5rem !important;
    width: .5rem !important;
    border-radius: 90px !important;
    border: 1px solid #fff !important;
}

.carousel__slide {
    width: 100% !important;
}


@media screen and ( min-width: 0px) and ( max-width: 651px ) {
    .arrow-button {
        height: 40px;
    }
}

</style>