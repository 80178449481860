<template>
    <div class="category-parent-top-section" @click.prevent="goToDetails()">
        <div class="top-category-item">
            <div class="top-category-item-img" :style="{ backgroundImage: `url(${primaryImageUrl})` }"></div>
        </div>
        <p class="top-category-type">{{ title }}</p>
    </div>
</template>

<script setup>
const router = useRouter();

const props = defineProps({
    item: {
        type: Object,
        default: null
    },
});

const itemRef = toRef(props, 'item');

const {
    primaryImageUrl,
    title,
} = useItemDto(itemRef.value);

const goToDetails = async ()=> {
    router.push({
        name: 'details',
        query: {id: itemRef.value.id},
    })
}
</script>

<style lang="scss">
.furniture-type-top-category {
    .furniture-top-progressive-image {
        background: none !important;
    }
}

.top-category-item-img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
</style>