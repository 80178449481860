<template>
    <div class="desktop-section" >
        <div class="furniture-type-top-category">
            <carousel
                style="width: 100%;"
                ref="myCarousel"
                :breakpoints="breakpoints"
                :settings="settings"
                :wrap-around="true"
                class="furniture-type-top"
            >
                <template #slides>
                    <slide v-for="(item, k) in items" :key="k">
                        <ProductItem :item="item"/>
                    </slide>
                </template>

                <template #addons>
                    <Navigation />
                </template>
            </carousel>
        </div>
    </div>
</template>

<script setup>
import { ref, toRef, computed } from "vue";
import ProductItem from './items/ProductItem.vue';

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    }
})

const settings = ref({
	itemsToShow: 7,
	snapAlign: "center",
});

const breakpoints = ref(
  {
    309: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    500: {
      itemsToShow: 2,
      snapAlign: "center",
    },
    700: {
      itemsToShow: 3,
      snapAlign: "center",
    },
    991: {
      itemsToShow: 4,
      snapAlign: "center",
    },
    1275: {
      itemsToShow: 5,
      snapAlign: "center",
    },
    1600: {
      itemsToShow: 6,
      snapAlign: "center",
    },
    1700: {
      itemsToShow: 7,
      snapAlign: "center",
    },
  }
);

</script>

<style lang="scss">

.furniture-type-top-category {
    .furniture-type-top {
        .furniture-top-progressive-imag {
            background: none !important;
            div {
                .v-progressive-image-main {
                    object-position: center;
                    object-fit: cover;
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
}
@media screen and (min-width: 577px ) {
    .mobile-section {
        display: none;
    }
}

@media screen and ( max-width: 576px ) {
    .furniture-type-top-category {
        .furniture-type-top {
            .furniture-top-progressive-imag {
                div {
                    .v-progressive-image-main {
                        object-position: center;
                        object-fit: cover;
                        height: auto;
                        width: 70%;
                        margin: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .furniture-type-top {
        .carousel__prev,
        .carousel__next {
            
        }
    }
}
.top-furniture-mobile-section {
    display: none;
}

</style>