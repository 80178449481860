<template>
    <div class="category-parent-section" v-if="categoryRef.isLoading" @click.prevent="goToDetailPage()">
        <img class="category-item" :src="image" alt="">
        <p class="category-type"> {{ name }}></p>
    </div>

    <div class="category-parent-section" v-else @click.prevent="goToDetailPage()">
        <div class="category-item">
            <div class="category-item-img" :style="{ backgroundImage: `url(${image})` }"></div>
        </div>
        <p class="category-type" @click.prevent="onGotoDetailePage"> {{ name }}</p>
    </div>
</template>

<script setup>
import { toRef, computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
    category: {
        type: Object,
        default: null,
    }
});

const categoryRef = toRef(props, 'category');

const name = computed(() => {
    
    if (categoryRef.value && categoryRef.value.name && categoryRef.value.name.length > 0) {
        return categoryRef.value.name;
    }

    return '';
});

const image = computed(() => {
    let img = "/images/no-image.png";
    if (categoryRef.value && categoryRef.value.extras) {
        if (categoryRef.value.extras.thumbnail_image_url && categoryRef.value.extras.thumbnail_image_url.length > 0) {
            img = categoryRef.value.extras.thumbnail_image_url;
        } else if (categoryRef.value.extras.banner_image1_url && categoryRef.value.extras.banner_image1_url.length > 0) {
            img = categoryRef.value.extras.banner_image1_url;
        }
    }
    return img;
});

const goToDetailPage = async () => {
    let obj = {
        categoryId: categoryRef.value && categoryRef.value.id ? categoryRef.value.id : '', 
        categoryName: name.value,
        categoryBanner: image.value,
    }
    router.push({
        name: "shop",
        query: obj,
    });
};

</script>

<style lang="scss">

.category-item-img {

    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

@media screen and (max-width: 500px) {
    .category-parent-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
</style>